
import tService from "@/service/tService";
import { cookieService } from "@/service/cookieConsentService";
import amountFormatter from '../filters/amountFormatter'

export default class tTransactionInformationService {
  constructor() {
    this.tService = new tService();
    this.transaction = {
      transactionID: '',
      item: [],
      total: {
        transactionTotalPoints: "0",
        additionalPaymentSum: "0",
        usedPoints: "0",
        shippingFee: "0",
        shippingMethod: "post_at",
        additionalPaymentMethod: "",
        additionalPaymentFee: "0",
        voucherDiscount: "0",
      }
    };

  }

  setTransactionInformation = (order) => {
    this.transaction.transactionID = String(order.order_id);
    this.transaction.total.transactionTotalPoints = String(order.grand_total_without_discounts);
    this.transaction.total.usedPoints = String(order.grand_total_without_discounts);
    this.transaction.total.additionalPaymentSum = amountFormatter(String(order.additional_payment));

    if (order.additional_payment > 0) {
      var usedPoints = order.discounts.filter((discount) => {
        return discount.type_id === "POINTS"
      });

      if (usedPoints.length === 1) {
        this.transaction.total.usedPoints = String(usedPoints[0].value);
      }
    }
    var voucherDiscount = order.discounts.filter((discount) => {
      return discount.type_id === "VOUCHER"
    });
    if (voucherDiscount.length === 1) {
      this.transaction.total.voucherDiscount = String(voucherDiscount[0].value);
    }


    this.transaction.total.additionalPaymentMethod = order.additional_payment_code;
    var mapping = {
      "rechnung": "invoice"
    };

    if (mapping[order.additional_payment_code]) {
      this.transaction.total.additionalPaymentMethod = mapping[order.additional_payment_code];
    }

    order.items.forEach((item) => {
      var transactionItem = {

        productInfo: {
          productID: String(item.product_sku),
          productName: item.name
        },
        category: {
          primaryCategory: item.cat_name
        },
        quantity: String(item.qty),
        price: {
          totalPoints: String(item.total)
        }
      };

      this.transaction.item.push(transactionItem);
    });
  };

  init(forceInit) {
    if (!window.digitalData_rewardsshop || (forceInit === true && cookieService.isStatisticsGroupEnabled())) {
      this.tService.init();
    }
    if (window.digitalData_rewardsshop.transaction) {
      delete window.digitalData_rewardsshop.transaction;
    }
  }

  get() {
    return this.transaction;
  }

  submit() {
    if (cookieService.isStatisticsGroupEnabled()) {
      this.tService.setTransactionInfo(this.transaction);
      this.tService.track();
    }
  }

}