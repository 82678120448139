export default function trustedShopService() {
    return new Promise(function (resolve, reject) {
        _tsConfig = {
            'yOffset': '0', /* offset from page bottom */
            'variant': 'reviews', /* default, reviews, custom, custom_reviews */
            'customElementId': '', /* required for variants custom and custom_reviews */
            'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
            'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
            'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
            'disableResponsive': 'false', /* deactivate responsive behaviour */
            'disableTrustbadge': 'false' /* deactivate trustbadge */
        };
        var _tsid = "X1B804ABF1400BAB98DCD909EAAD13EC5";

        var d3url = "//widgets.trustedshops.com/js/" + _tsid + ".js";
        var s = document.createElement("script");
        s.type = 'text/javascript';
        s.charset = 'utf-8';
        s.async = true;
        s.onload = function (ev) {
            resolve(ev);
        };
        s.onerror = function (ev) {
            reject(ev);
        };
        s.src = d3url;
        ///$rootElement[0].appendChild(s);
        document.body.appendChild(s);
    });
}