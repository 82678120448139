<template>
      <section>
            <nav-menu></nav-menu>
            <div class="page-content-lightestblue" data-rewardsshop-action="succcess">
                  <div class="page-content-lightestblue__container">
                        <div class="page-content-lightestblue__container-colLeft">
                              <h2>{{ t("LBL_ORDER_SUCCESS_TITLE") }}</h2>
                              <div class="py-3">
                                    {{ t("LBL_ORDER_SUCCESS_TEXT") }}
                              </div>
                              <div>{{ t("LBL_ORDER_NO") }}</div>
                              <div>
                                    <strong>{{ orderNumber }}</strong>
                              </div>
                              <div class="py-3 text-blue">
                                    <router-link data-rewardsshop-action="shop-orderHistory" :to="'/praemien/meine-bestellungen'">
                                          {{ t("LBL_MY_ORDERS") }}
                                    </router-link>
                              </div>

                              <div class="py-3">
                                    <Button :css-class="'blue'" data-rewardsshop-action="shop-products" :text="t('LBL_BACK_TO_START_PAGE')" class="w-full" @click="goToLandingPage()"></Button>
                              </div>
                        </div>
                        <div class="page-content-lightestblue__container-colRight">
                              <app-image :alt="order - confirmation" :src="require('../assets/img/order-confirmation-picture-data.png')"></app-image>
                        </div>
                  </div>
            </div>
            <div class="ocp-teaser">
                  <a class="xxs:hidden xs:hidden sm:block md:block lg:block" v-if="cms?.desktop?.id" :href="`${cms.desktop.url}`" target="_blank" :title="`${cms.desktop.tag}`"
                        ><picture>
                              <source media="(min-width:600px)" :srcset="`${cms.desktop.imageName}`" />
                              <app-image :alt="`${cms.desktop.tag}`" :src="`${cms.desktop.imageName}`"></app-image> </picture
                  ></a>
                  <a class="xxs:block xs:block sm:hidden md:hidden lg:hidden" v-if="cms?.mobile?.id" :href="`${cms.mobile.url}`" target="_blank" :title="`${cms.mobile.tag}`"
                        ><picture>
                              <source media="(min-width:320px)" :srcset="`${cms.mobile.imageName}`" />
                              <app-image :alt="`${cms.mobile.tag}`" :src="`${cms.mobile.imageName}`"></app-image> </picture
                  ></a>
            </div>
            <div>abc{{ test }}</div>
            <!--****************************************************-->
            <!--****************TrustetShops Badge*******************-->
            <!--****************************************************-->
            <div id="trustedShopsCheckout" style="display: none" v-if="lastOrder && lastOrder?.order_id">
                  <span id="tsCheckoutOrderNr">{{ lastOrder.order_id }}</span>
                  <span id="tsCheckoutBuyerEmail">{{ lastOrder?.customer_email }}</span>
                  <span id="tsCheckoutOrderAmount">{{ lastOrder?.grand_total_without_discounts / 100 }}</span>
                  <span id="tsCheckoutOrderCurrency">EUR</span>
                  <span id="tsCheckoutOrderPaymentType">{{ lastOrder?.additional_payment_code }}</span>
                  <span id="tsCheckoutOrderEstDeliveryDate">{{ lastOrder?.delivery_date }}</span>
            </div>
            <!--****************************************************-->
            <!--****************END TrustetShops Badge*******************-->
            <!--****************************************************-->
      </section>
</template>

<script>
      import Button from "@/components/Button/Button";
      import NavMenu from "@/components/Menu/NavMenu";
      import AppImage from "@/components/Image/Image";
      import trustedshopsService from "@/service/trustedShopService.js";
      import tTransactInformationService from "@/service/tTransactionInformationService";
      import { cookieService } from "@/service/cookieConsentService";
      import { useRoute, useRouter } from "vue-router";
      import { computed, onMounted, ref } from "vue";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";

      export default {
            name: "OrderSuccess",
            components: {
                  NavMenu,
                  AppImage,
                  Button
            },
            setup() {
                  const { t } = useI18n();
                  const router = useRouter();
                  const route = useRoute();
                  const store = useStore();
                  const orderNumber = ref("");
                  const lastOrder = ref({});
                  const tTransactionInformationService = new tTransactInformationService();

                  // @todo send a call to "'checkout/lastorder'" to get the last order id
                  const goToLandingPage = async () => {
                        store.dispatch("catalog/setFilters", {});
                        store.dispatch("catalog/emptyProductsList");
                        router.push({
                              name: "home",
                              params: {}
                        });
                  };

                  const isExpressCheckout = () => {
                        return route.name === "express-bestellen";
                  };

                  onMounted(async () => {
                        setTimeout(async () => {
                              const response = await store.dispatch("cart/getLastOrder");
                              const orderResp = await store.dispatch("order/getList");
                              let lastOrderVal = orderResp.items.filter((el) => {
                                    return String(el.order_id) === String(response.last_order_number);
                              });

                              store.dispatch("cms/getMainPageContents");
                              orderNumber.value = response.last_order_number;

                              if (lastOrderVal.length === 1) {
                                    lastOrderVal = lastOrderVal[0];

                                    // Delivery date is an approximate estimation of the order creation date plus 4 days
                                    // Javascript is not good when it comes to working with dates so we have to do everything manually
                                    var orderDate = lastOrderVal.created_at;

                                    var dt = parseInt(orderDate.substring(8, 10));
                                    var mon = parseInt(orderDate.substring(5, 7));
                                    var yr = parseInt(orderDate.substring(0, 4));
                                    var deliveryDateObj = new Date(yr, mon - 1, dt + 4);
                                    var deliveryDate = deliveryDateObj.toLocaleString("en-GB").slice(0, 10);
                                    lastOrderVal["delivery_date"] = deliveryDate;
                                    lastOrder.value = lastOrderVal;
                                    if (cookieService.isStatisticsGroupEnabled()) {
                                          // Send the info to transaction tracking service 
                                          tTransactionInformationService.init(true);
                                          tTransactionInformationService.setTransactionInformation(lastOrder?.value);
                                          tTransactionInformationService.submit();
                                    }

                                    trustedshopsService().then(console.log);
                              }

                              if (orderNumber.value && !isExpressCheckout()) {
                                    await store.dispatch("cart/emptyCart");
                              }
                        }, 1000);
                  });

                  return {
                        goToLandingPage,
                        orderNumber,
                        lastOrder,
                        t,
                        cms: computed(() => {
                              let cms = store.getters["cms/getMainPageContents"];
                              cms = Object.values(cms);
                              if (cms && cms.length > 0) {
                                    return cms
                                          .filter(function (data) {
                                                return ["OCP_TEASER_DESKTOP_IMAGE", "OCP_TEASER_MOBILE_IMAGE"].includes(data.tag);
                                          })
                                          .reduce(
                                                (aggregate, item) => {
                                                      if (item.mobileOnly == 1) {
                                                            aggregate.mobile = { imageName: item.imageName, url: item.url, tag: item.tag, id: item.id };
                                                      } else {
                                                            aggregate.desktop = { imageName: item.imageName, url: item.url, tag: item.tag, id: item.id };
                                                      }
                                                      return aggregate;
                                                },
                                                { desktop: {}, mobile: {} }
                                          );
                              }
                              return {};
                        })
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .page-content-lightestblue {
            &__container {
                  @apply grid grid-cols-3 gap-2 grid-flow-row-dense  text-left;
                  &-colLeft {
                        @apply col-span-1;
                        @media (max-width: 600px) {
                              @apply col-span-1;
                        }
                  }
                  &-colRight {
                        @apply col-span-2;
                  }
                  @media (min-width: 992px) {
                        @apply grid-cols-3;
                  }
                  @media (max-width: 800px) {
                        @apply grid-cols-1;
                  }
            }
      }
      .ocp-teaser img {
            max-width: 100%;
            width: 940px;
            height: 120px;
            margin: 0 auto;

            @media (max-width: 767px) {
                  padding: 0 1rem;
            }

            @media (max-width: 600px) {
                  width: 460px;
            }
      }
</style>
